import { addUserLog } from '@avensia/scope';
import { Dispatch, Action } from 'Shared/State';
import { batchActions } from 'redux-batched-actions';

export type OpenConfirmationPanelAction = Action & {};
export type CloseConfirmationPanelAction = Action & {};

export const CLOSE_CONFIRMATION_PANEL = 'CLOSE_CONFIRMATION_PANEL';
export const OPEN_CONFIRMATION_PANEL = 'OPEN_CONFIRMATION_PANEL';

export function openConfirmationPanel() {
  addUserLog('Opening Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch(
      batchActions([
        {
          type: OPEN_CONFIRMATION_PANEL,
        } as OpenConfirmationPanelAction,
      ]),
    );
  };
}

export function closeConfirmationPanel() {
  addUserLog('Closing Newsletter subscribe overlay');
  return (dispatch: Dispatch) => {
    dispatch({
      type: CLOSE_CONFIRMATION_PANEL,
    } as CloseConfirmationPanelAction);
  };
}
