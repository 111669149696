import React from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@glitz/react';
import Overlay from 'Shared/Overlay';
import Button, { Appearance as ButtonAppearance, Variant as ButtonVariant } from 'Shared/Button';
import { epiPropertyValue, Breakpoint } from '@avensia/scope';
import * as style from 'Shared/Style';
import { media } from '@glitz/core';
import Close from 'Shared/Icon/Close';
import HexagonButton from 'Shared/Button/Hexagon';

type PropType = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
  confirmationHeading: Scope.Property<string>;
  confirmationMessage: Scope.Property<string>;
  confirmationButton: Scope.Property<string>;
};

type StateType = {
  onSubscribeAccept: boolean;
};

const Base = styled.div({
  position: 'fixed',
  width: '100%',
  height: '100%',
  left: '50%',
  bottom: 0,
  backgroundColor: style.WHITE,
  transform: 'translateX(-50%)',
  padding: {
    x: style.medium,
    y: style.medium,
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: 'auto',
    width: '95%',
    maxWidth: '500px',
    minHeight: '100px',
    left: '50%',
    bottom: '50%',
    boxShadow: '0 0 5px 1px #979797',
  }),
});

const InnerContainer = styled.div({
  position: 'relative',
  paddingRight: '30px',
});

const ConfirmationInfo = styled.div({
  width: '100%',
});

const ConfirmationHeading = styled.h3({
  margin: {
    bottom: '19px',
  },
  fontSize: '26px',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    marginRight: '7px',
    fontSize: '34px',
  }),
});

const ConfirmationMessage = styled.p({
  margin: {
    bottom: 0,
  },
});

const ButtonText = styled.span({
  margin: {
    bottom: 0,
  },
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: {
    bottom: '14px',
    top: '35px',
  },
});

const CloseButton = styled(Button, {
  width: '46px',
  position: 'absolute',
  right: 0,
  top: 0,
});

export default class NewsLetterModal extends React.Component<PropType, StateType, {}> {
  rootElement: HTMLElement;
  constructor(props: PropType) {
    super(props);
  }
  componentDidMount() {
    this.rootElement = document.getElementById('content-container');
  }

  render() {
    return this.rootElement
      ? ReactDOM.createPortal(
          <Overlay enabled={this.props.isOpen} onClose={this.props.onClose}>
            {this.props.isOpen && (
              <Base>
                <InnerContainer>
                  <ConfirmationInfo>
                    <ConfirmationHeading>{epiPropertyValue(this.props.confirmationHeading)}</ConfirmationHeading>
                    <CloseButton
                      appearance={ButtonAppearance.Bare}
                      variant={ButtonVariant.Small}
                      onClick={this.props.onClose}
                    >
                      <Close />
                    </CloseButton>
                    <ConfirmationMessage>{epiPropertyValue(this.props.confirmationMessage)}</ConfirmationMessage>
                  </ConfirmationInfo>
                </InnerContainer>
                <ButtonContainer>
                  <HexagonButton
                    onClick={this.props.onClose}
                    appearance={ButtonAppearance.Primary}
                    color={style.primaryDark}
                    style={{ textDecoration: 'none' }}
                  >
                    <ButtonText>{epiPropertyValue(this.props.confirmationButton)}</ButtonText>
                  </HexagonButton>
                </ButtonContainer>
              </Base>
            )}
          </Overlay>,
          document.getElementById('container'),
        )
      : null;
  }
}
