/**
 * @ComponentFor ContactPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, postJson, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import ContactPageViewModel from './ContactPageViewModel.type';
import Input from 'Shared/Fields/Text';
import * as style from 'Shared/Style';
import Viewport from 'Shared/Viewport';
import { translate } from '@avensia/scope';
import ContactRequestType from './ContactRequestType.type';
import { Main, Appearance, Heading } from 'Shared/PageLayout';
import { media } from '@glitz/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import connect from 'Shared/connect';
import ContactResultViewModel from './ContactResultViewModel.type';
import ConfirmationModal from './ConfirmationModal';
import { closeConfirmationPanel, openConfirmationPanel } from './action-creators';
import HexagonButton from 'Shared/Button/Hexagon';

type StateType = {
  formValues: {
    name: string;
    company: string;
    email: string;
    subject: string;
    message: string;
    type: ContactRequestType;
    googleReCAPTCHAResponse: any;
  };
  captchaAccept: boolean;
  errorMessage: string;
  isDisabled: boolean;
  showTooltip: boolean;
};

type ConnectStateType = {
  confirmationPanelIsOpen: boolean;
  googleReCaptchaSiteKey: string;
};

type DispatchPropType = {
  closeConfirmationPanel: () => void;
  openConfirmationPanel: () => void;
};

type PropType = ContactPageViewModel &
  StateType &
  ConnectStateType &
  DispatchPropType & {
    googleReCaptchaSiteKey: string;
  };

class ContactPage extends React.Component<PropType, StateType> {
  reCAPTCHA: React.RefObject<ReCAPTCHA>;
  constructor(props: PropType) {
    super(props);

    this.state = {
      formValues: {
        name: '',
        company: '',
        email: '',
        subject: '',
        message: '',
        googleReCAPTCHAResponse: '',
        type: epiPropertyValue(this.props.content.type),
      },
      errorMessage: null,
      captchaAccept: false,
      isDisabled: true,
      showTooltip: false,
    };
    this.reCAPTCHA = React.createRef<ReCAPTCHA>();
    this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
  }

  onReCaptchaChange(value: any) {
    if (!value) {
      this.setState({
        captchaAccept: false,
      });
      return;
    }
    const formValues = this.state.formValues;
    formValues.googleReCAPTCHAResponse = value;
    this.setState({
      formValues,
      captchaAccept: true,
    });
    if (
      this.state.formValues.name &&
      this.state.formValues.company &&
      this.state.formValues.email &&
      this.state.formValues.subject &&
      this.state.formValues.message &&
      this.state.captchaAccept
    ) {
      this.setState({
        isDisabled: false,
      });
    } else {
      this.setState({
        isDisabled: true,
      });
    }
  }

  handleChange(event: any) {
    event.preventDefault();
    const formValues = this.state.formValues;
    const name = event.target.name;
    const value = event.target.value;
    formValues[name] = value;
    this.setState({
      formValues,
      errorMessage: null,
    });
    if (
      this.state.formValues.name &&
      this.state.formValues.company &&
      this.state.formValues.email &&
      this.state.formValues.subject &&
      this.state.formValues.message &&
      this.state.captchaAccept
    ) {
      this.setState({
        isDisabled: false,
      });
    } else {
      this.setState({
        isDisabled: true,
      });
    }
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.closeErrorMessage();
    this.setState({
      isDisabled: true,
    });
    postJson('/contactpage/contact', this.state.formValues)
      .then((result: ContactResultViewModel) => {
        if (result.success) {
          this.props.openConfirmationPanel();
          const formValues = Object.assign({}, this.state.formValues);
          (formValues.name = ''),
            (formValues.company = ''),
            (formValues.email = ''),
            (formValues.subject = ''),
            (formValues.message = ''),
            (formValues.googleReCAPTCHAResponse = ''),
            this.setState({
              formValues,
              captchaAccept: false,
            });
          this.reCAPTCHA.current.reset();
          return Promise.resolve();
        } else {
          this.setState({
            errorMessage: result.message,
          });
          return Promise.reject(result);
        }
      })
      .catch(error => {
        this.setState({ errorMessage: error.message });
        return Promise.reject(null);
      });
    this.setState({ isDisabled: true });
  }

  closeErrorMessage = (): void => {
    this.setState({ errorMessage: null });
  };

  handleMouseHover = () => {
    if (this.state.isDisabled) {
      this.setState({
        showTooltip: !this.state.showTooltip,
      });
      setTimeout(() => {
        this.setState({
          showTooltip: !this.state.showTooltip,
        });
      }, 4000);
    }
  };

  render() {
    return (
      <>
        <BkgPattern />
        <MenuBkg />
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          <HeadingWrapper>
            <Heading title={this.props.content.name} breadcrumbs={this.props.breadcrumbs} />
          </HeadingWrapper>
          <Content>
            <Form onSubmit={this.handleSubmit.bind(this)}>
              <Information>
                <EpiProperty for={this.props.content.informationalText} />
              </Information>

              <InputLabel>{translate('/ContactPage/ContactName')}</InputLabel>
              <InputField
                required
                type="text"
                name="name"
                value={this.state.formValues.name}
                onChange={this.handleChange.bind(this)}
              />

              <InputLabel>{translate('/ContactPage/ContactCompany')}</InputLabel>
              <InputField
                required
                type="text"
                name="company"
                value={this.state.formValues.company}
                onChange={this.handleChange.bind(this)}
              />

              <InputLabel>{translate('/ContactPage/ContactEmail')}</InputLabel>
              <InputField
                required
                type="text"
                name="email"
                value={this.state.formValues.email}
                pattern=".+@.+\..+"
                onChange={this.handleChange.bind(this)}
              />

              <Line />

              <InputLabel>{translate('/ContactPage/ContactSubject')}</InputLabel>
              <InputField
                required
                type="text"
                name="subject"
                value={this.state.formValues.subject}
                onChange={this.handleChange.bind(this)}
              />

              <InputLabel>{translate('/ContactPage/ContactMessage')}</InputLabel>
              <TextArea
                required
                name="message"
                value={this.state.formValues.message}
                onChange={this.handleChange.bind(this)}
              />

              <CaptchaBox>
                <ReCAPTCHA
                  ref={this.reCAPTCHA}
                  sitekey={this.props.googleReCaptchaSiteKey}
                  onChange={this.onReCaptchaChange}
                />
              </CaptchaBox>
              {this.state.errorMessage && <Error>{this.state.errorMessage}</Error>}
              <FormBottom>
                <ButtonContainer>
                  {this.state.showTooltip &&
                    this.props.content.tooltipText && (
                      <TooltipText>{epiPropertyValue(this.props.content.tooltipText)}</TooltipText>
                    )}
                  <HexagonButton
                    type="submit"
                    value="Submit"
                    id="button"
                    disabled={this.state.isDisabled ? true : false}
                    appearance={this.state.isDisabled ? ButtonAppearance.Disabled : ButtonAppearance.Primary}
                    onMouseEnter={this.handleMouseHover}
                    fill={this.state.isDisabled ? style.tertiaryDark : style.primary}
                  >
                    <ContactButtonText>{epiPropertyValue(this.props.content.buttonText)}</ContactButtonText>
                  </HexagonButton>
                </ButtonContainer>
                <ConfirmationModal
                  isOpen={this.props.confirmationPanelIsOpen}
                  onClose={this.props.closeConfirmationPanel}
                  confirmationMessage={this.props.content.confirmationMessage}
                  confirmationButton={this.props.content.confirmationButton}
                  confirmationHeading={this.props.content.confirmationHeading}
                />
                <Disclaimer>
                  <EpiProperty for={this.props.content.disclaimerText} />
                </Disclaimer>
              </FormBottom>
            </Form>
            <Viewport>
              {(isCompact: boolean) =>
                !isCompact && (
                  <DemoRequest>
                    <InformationBox>
                      <Hexagon>
                        <HexagonTitle>{epiPropertyValue(this.props.content.hexagonTitle)}</HexagonTitle>
                        <HexagonText>
                          <EpiProperty for={this.props.content.hexagonText} />
                        </HexagonText>
                      </Hexagon>
                    </InformationBox>
                  </DemoRequest>
                )
              }
            </Viewport>
          </Content>
        </MainWrapper>
      </>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    confirmationPanelIsOpen: state.currentUser.confirmationPanelIsOpen,
    googleReCaptchaSiteKey: state.appShellData.googleReCaptchaSiteKey,
  }),
  (dispatch): DispatchPropType => ({
    openConfirmationPanel() {
      return dispatch(openConfirmationPanel());
    },
    closeConfirmationPanel() {
      return dispatch(closeConfirmationPanel());
    },
  }),
)(ContactPage as React.ComponentClass<PropType>);

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '19px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '80px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    maxWidth: 1440,
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const HeadingWrapper = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    paddingTop: '70px',
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  }),
});

const Disclaimer = styled.p({
  margin: {
    top: '30px',
    bottom: '75px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '49px',
      bottom: '100px',
    },
  }),
});

const Content = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  lineHeight: '32px',
  margin: {
    top: '23px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    margin: {
      top: '27px',
    },
  }),
});

const Form = styled.form({
  width: '100%',
  zIndex: 0,
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    maxWidth: '590px',
  }),
});

const FormBottom = styled.div({
  margin: {
    top: '30px',
  },
  ...media(style.mediaMaxQueries[Breakpoint.Small], {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
});

const Information = styled.p({
  margin: {
    bottom: '30px',
  },
});

const HexagonTitle = styled.h1({
  position: 'absolute',
  top: '40px',
  left: '35px',
  width: '200px',
  height: '200px',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: 500,
  letterSpacing: -0.69,
  color: style.primary,
});

const HexagonText = styled.p({
  position: 'absolute',
  top: '94px',
  left: '15px',
  width: '245px',
  height: '200px',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 300,
  letterSpacing: -0.69,
  zIndex: 1,
});

const Hexagon = styled.div({
  position: 'relative',
  display: 'block',
  width: '350px',
  height: '200px',
  marginBottom: '23px',
  textAlign: 'center',
  outlineWidth: 0,
  fontSize: '15px',
  color: style.BLACK,
  textDecoration: 'none',
  top: '45px',

  border: {
    xy: {
      width: 0,
    },
  },
  '::before': {
    width: '270px',
    left: 0,
    top: '-50px',
    height: '160px',
    transform: 'perspective(15px) rotateX(3.7deg)',
    position: 'absolute',
    backgroundColor: style.WHITE,
    content: '""',
    boxShadow: '3px 3px 3px -3px rgba(0,0,0,0.12), 0px 0px 5px rgba(0,0,0,0.12)',
  },
  '::after': {
    width: '270px',
    left: 0,
    top: '192.5px',
    height: '160px',
    position: 'absolute',
    backgroundColor: style.WHITE,
    content: '""',
    transform: 'perspective(15px) rotateX(-3.7deg)',
    boxShadow: '-5px 5px 6px rgba(0,0,0,0.12), 5px 6px 9px rgba(0,0,0,0.12)',
  },
});

const InformationBox = styled.div({});

const DemoRequest = styled.div({
  marginLeft: '120px',
});

const Line = styled.p({
  height: '1px',
  boxSizing: 'border-box',
  borderBottom: {
    color: style.primaryDark,
    width: '1px',
    style: 'solid',
  },
  marginTop: '31.25px',
  marginBottom: '31.25px',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '1px',
    boxSizing: 'border-box',
    borderBottom: {
      color: style.primaryDark,
      width: '1px',
      style: 'solid',
    },
    marginBottom: '49.5px',
    marginTop: '38px',
  }),
});

const InputLabel = styled.label({
  color: style.primary,
  fontSize: '21px',
  fontWeight: 500,
  letterSpacing: -0.52,
  marginBottom: '0px',
  marginTop: '5px',
});

const InputField = styled(Input, {
  width: '100%',
  marginBottom: '9.5px',
});

const TextArea = styled.textarea({
  width: '100%',
  marginBottom: '10px',
  height: '219px',
  padding: {
    top: '10px',
    bottom: '10px',
    left: '12px',
  },
  border: {
    xy: {
      style: 'solid',
      color: style.tertiaryLightest,
      width: '1px',
    },
  },
  ':invalid': {
    boxShadow: 'none',
  },
  resize: 'none',
});

const ContactButtonText = styled.span({
  zIndex: 1,
  color: style.WHITE,
  position: 'relative',
  textAlign: 'center',
});

const Error = styled.p({
  color: style.RED,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  margin: {
    top: '20px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    justifyContent: 'start',
  }),
});

const CaptchaBox = styled.div({
  ...media(style.mediaMaxQueries[Breakpoint.Small], {
    width: '304px',
    margin: {
      left: 'auto',
      right: 'auto',
    },
  }),
});

const ButtonContainer = styled.div({
  position: 'relative',
  display: 'flex',
});

const TooltipText = styled.span({
  position: 'absolute',
  zIndex: 1,
  background: style.primaryDark,
  color: style.WHITE,
  minWidth: '200px',
  textAlign: 'center',
  padding: {
    left: '5px',
    right: '5px',
  },
  bottom: '50px',
  left: '-20%',
  borderRadius: '6px',
  ':after': {
    content: '"',
    position: 'absolute',
    top: '100%',
    left: '50%',
    marginLeft: '-8px',
    width: 0,
    height: 0,
    borderRadius: '6px',
    border: {
      top: {
        width: '8px',
        style: 'solid',
        color: style.primaryDark,
      },
      right: {
        width: '8px',
        style: 'solid',
        color: 'transparent',
      },
      left: {
        width: '8px',
        style: 'solid',
        color: 'transparent',
      },
    },
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    left: '-6%',
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const MenuBkg = styled.div({
  position: 'fixed',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    position: 'absolute',
  }),
  background: style.primaryDark,
  zIndex: style.ZIndex.BelowCover,
  top: 0,
  width: '100%',
  height: '65px',
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    height: '80px',
  }),
});
